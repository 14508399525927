import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import "./common.css"
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import App from './App.vue'
import router from './router/index'
import store from './store'
import Router from 'vue-router'
Vue.use(Router)

Vue.use(ElementUI)

Vue.config.productionTip = false

//处理重复点击跳转报错
const originalPush = Router.prototype.push
   Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
// var authed = ["/upgrading"] //不需要登陆能访问的页面

// 验证用户token是否失效，如果失效跳转登录页面
// router.beforeEach((to, from, next) => {
//   if (authed.indexOf(to.path) != -1) { //不需要登陆能访问的页面 直接跳转
//     console.log(to.path)
//     next();
//   } else if (to.matched.some(record => record.meta.requiresAuth)) {
//     //这里判断用户是否登录，验证本地存储是否有token
//     if (!localStorage.access_token) { // 判断当前的token是否存在
//       // localStorage.setItem("autoLogin", "");
//       next({
//         path: '/login',
//         // query: { redirect: to.fullPath }
//       })
//     } else {
//       next()
//     }
//   } else {
//     next() // 确保一定要调用 next()
//   }
// });

// router.beforeEach((to, from, next)=>{
//   if(to.meta.title){
//     document.title = to.meta.title
//   }
//   next()
// })
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')