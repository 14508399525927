import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Home = () => import('@/views/Home.vue')//首页
const Login = () => import('@/views/Login.vue')//登录
const ServicePrice = () => import('@/views/ServicePrice.vue')//服务价格表
const PreferentialPolicy = () => import('@/views/PreferentialPolicy.vue')//服务政策
const SecurityConvention = () => import('@/views/SecurityConvention.vue')//安全约定
const OnlineTo = () => import('@/views/OnlineTo.vue')//在线预约

const routes = [
  {//首页
    path: '/',
    name: 'Home',
    component: Home
  },
  {//登录
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {//服务价格表
    path: '/ServicePrice',
    name: 'ServicePrice',
    component: ServicePrice
  },
  {//优惠政策
    path: '/PreferentialPolicy',
    name: 'PreferentialPolicy',
    component: PreferentialPolicy
  },
  {//安全约定
    path: '/SecurityConvention',
    name: 'SecurityConvention',
    component: SecurityConvention
  },
  {//在线预约
    path: '/OnlineTo',
    name: 'OnlineTo',
    component: OnlineTo
  }
]

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
