import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userName: localStorage.getItem("user_name"), //用户名
    password: localStorage.getItem("password"), //用户名
    userId: localStorage.getItem("user_id"), //用户名ID
    accessToken: localStorage.getItem("access_token"), //token
    activeIndex: localStorage.getItem("active_index"), //nav
    announcement: true //公告
  },
  mutations: {
    setUserName(state, n = "") { //用户名
      state.userName = n;
      localStorage.setItem("user_name", n);
    },
    setPassword(state, n = "") { //密码
      state.password = n;
      localStorage.setItem("password", n);
    },
    setUserId(state, n = "") { //用户名ID
      state.userId = n;
      localStorage.setItem("user_id", n);
    },
    setToken(state, n = "") { //token
      state.accessToken = n;
      localStorage.setItem("access_token", n);
    },
    setActiveIndex(state, n = "") { //nav
      state.activeIndex = n;
      localStorage.setItem("active_index", n);
    },
    setAnnouncement(state, n) { //nav
      state.announcement = n;
      // localStorage.setItem("active_index", n);
    }
  },
  actions: {},
  modules: {}
})